import styled from "styled-components";

import raszynLogoSrc from '../../assets/logos/logo-raszyn.png';
import hetmanLogoSrc from '../../assets/logos/logo-hetman.png';
import pruszkowLogoSrc from '../../assets/logos/logo-pruszkow.png';

const Container = styled.footer`
    padding: 3em 0 3em;
    line-height: 1.2em;
    letter-spacing: .15em;
    text-align: center;
    border-top: 2px solid #777777 ;

    display: 'flex';
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin: 2em 0;
    }

    .wrapper {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    img {
        max-width: 98%;
    }
`;

const Logos = function () {
    return <Container>
        <div className="wrapper">
            <p>Gra &bdquo;Sortowanie ważna sprawa&rdquo; zrealizowana przez Stowarzyszenie{'\u00A0'}Projekt{'\u00A0'}Raszyn</p>
            <img src={raszynLogoSrc} alt="Projekt raszyn 1809" />
            <p>W ramach zadania &bdquo;Środowisko - to się liczy&rdquo;  przy wsparciu z dotacji Powiatu{'\u00A0'}Pruszkowskiego:</p>
            <img src={pruszkowLogoSrc} alt="Powiat Pruszkowski - nieskończone możliwości" />
            <p>Sponsor: PU Hetman Sp. z o.o. </p>
            <img src={hetmanLogoSrc} alt="PU Hetman Sp. z o.o." />
        </div>
    </Container>
}

export default Logos;
