import { initUserModuleState, UserModuleAction, UserModuleState, USER_MODULE_ACTION } from ".";

export default function userReducer(state: UserModuleState, { type, payload }: UserModuleAction): UserModuleState {

    switch (type) {
        case USER_MODULE_ACTION.CREATE_USER:
            return {
                ...state,
                user: {
                    login: payload.name,
                    id: 0,
                    completedTasks: []
                }
            }

        case USER_MODULE_ACTION.SAVE_LIBRARY_CODE:
            return {
                ...state
            }

        case USER_MODULE_ACTION.COMPLETE_TASK:
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        completedTasks: Array.from(new Set([
                            ...state.user.completedTasks,
                            payload.taskId
                        ]))
                    }
                }
            }
            break;

        case USER_MODULE_ACTION.SET_TASK_COMPLETION:
            if (state.user) {
                if (payload.value) {
                    return {
                        ...state,
                        user: {
                            ...state.user,
                            completedTasks: Array.from(new Set([
                                ...state.user.completedTasks,
                                payload.taskId
                            ]))
                        }
                    }
                }


                return {
                    ...state,
                    user: {
                        ...state.user,
                        completedTasks: state.user.completedTasks.filter(t => t !== payload.taskId)
                    }
                }
            }
            break;


        case USER_MODULE_ACTION.SET_COMPLETE_GAME:
            return {
                ...state,
                gameCompleted: payload.value
            }

        case USER_MODULE_ACTION.RESET:
            return { ...initUserModuleState };

        case USER_MODULE_ACTION.RESET_TASKS:
            if (state.user) {
                return {
                    ...initUserModuleState
                };
            }
            break;
    }

    return state;
}
