import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import CookiesInfo from './components/cookies-info';
import Loader from './components/loader';
import Logos from './components/logos';
import PageHeader from './components/ui/page-header';
import { useMainModuleState } from './modules/main';
import useIsLogged from './modules/user/hooks/use-is-logged';

const LoggedRouter = lazy(() => import('./routers/logged'));
const UnloggedRouter = lazy(() => import('./routers/unlogged'));

const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    main.page {
        flex: 1 1 100%;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
`;

export default function App() {

    const { isLogged } = useIsLogged();
    const { pageHeader, showCookiesInfo, showLogos } = useMainModuleState();

    return <Container>
        {pageHeader && <PageHeader>{pageHeader}</PageHeader>}
        <main className="page">
            <Suspense fallback={<Loader />}>
                {isLogged && <LoggedRouter></LoggedRouter>}
                {!isLogged && <UnloggedRouter></UnloggedRouter>}
            </Suspense>
        </main>
        {showCookiesInfo && <CookiesInfo />}
        {showLogos && <Logos />}
    </Container>
}
