import { useUserModuleState } from "..";

export default function useUser() {
    const {user, gameCompleted} = useUserModuleState();

    return {
        isLogged: true,
        gameCompleted,
        user
    }
}
