import makeStore from "@mborecki/react-create-store";
import userReducer from "./reducer";
import { User } from "./types";

const USER_MODULE_LS_KEY = '_hetman_user';

export type UserModuleState = {
    user?: User,
    gameCompleted: boolean
}

const initUserModuleState = {
    gameCompleted: false
};

export enum USER_MODULE_ACTION {
    CREATE_USER = 'create-user',
    COMPLETE_TASK = 'complete-task',

    RESET = 'reset',
    RESET_TASKS = 'reset-tasks',
    SAVE_LIBRARY_CODE = 'save-library-code',
    SET_TASK_COMPLETION = 'set-task-completion',
    SET_COMPLETE_GAME = 'set-game-completed'
}

export type UserModuleAction = {
    type: USER_MODULE_ACTION,
    payload?: any
}

const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = makeStore(userReducer, initUserModuleState, USER_MODULE_LS_KEY)


export {
    initUserModuleState,
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}
