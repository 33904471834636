import styled from "styled-components";

const Container = styled.div`
    padding: 3em 0 6.5em;
    font-size: 18px;
    line-height: 1.2em;
    letter-spacing: .15em;
    text-align: center;
    border-top: 2px solid #777777 ;

    .wrapper {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    @media(max-width: 800px) {
        font-size: 16px;
        padding-bottom: 2em;
    }
`;

const CookiesInfo = function () {
    return <Container>
        <div className="wrapper">
            <p>"Strona korzysta z plików cookies: sesyjnych, stałych oraz podmiotów zewnętrznych. Korzystając z serwisu bez zmiany ustawień przeglądarki wyrażasz zgodę na stosowanie plików cookies zgodnie z <a href="https://www.puhetman.pl/pl/polityka-prywatnosci" target="_blank" rel="noreferrer">Polityką prywatności</a>"</p>
        </div>
    </Container>
}

export default CookiesInfo;
