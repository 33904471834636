import React from "react";
import styled from "styled-components";
import { THEME } from "../../../theme";

type Props = {}

const Container = styled.header`
    background: ${THEME.GREEN};

    h1 {

        font-family: ${THEME.FONT_2};
        color: white;
        font-size: 2.6rem;
        font-weight: 600;

        letter-spacing: .15em;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto;

        word-break: break-word;
    }

    padding: .55em 0 .45em;
    text-transform: uppercase;

    @media (max-width: 800px) {
        h1 {
            font-size: 1.3rem;
        }
    }
`;

const PageHeader: React.FC<Props> = ({ children }) => {
    return <Container>
        <h1>
            {children}
        </h1>
    </Container>
}

export default PageHeader;

