export const THEME = {
    GREEN: '#428247',
    GREEN_2: '#1f4922',
    RED: '#ff0000',
    RED_2: '#ec1c24',
    YELLOW: '#ffc937',
    YELLOW_2: '#ac9237',
    FONT_1: "'Montserrat', sans-serif",
    FONT_2: "'Prompt', sans-serif"
}
