import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

export type MainModuleState = {
    isLoading: boolean,
    pageHeader: string,
    showCookiesInfo: boolean,
    showLogos: boolean

}

const initMainModuleState = {
    isLoading: false,
    pageHeader: '',
    showCookiesInfo: false,
    showLogos: false
}

export enum MAIN_MODULE_ACTION {
    FETCH_CONFIG,
    FETCH_CONFIG_SUCCESS,
    SET_PAGE_HEADER,
    SET_COOKIES_INFO,
    SET_LOGOS
}

export type MainModuleAction = {
    type: MAIN_MODULE_ACTION,
    payload?: any
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = makeStore(reducer, initMainModuleState)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}
